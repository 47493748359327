import React, { Component } from 'react'
import Container from '../organisms/PageContainer'

export default class About extends Component {
    render() {
        return (
            <Container id="about">
                This is the About Page
            </Container>
        )
    }
}
